import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

const UserList = () => {
    // Example users data - replace with real data
    const [users, setUsers] = useState([]);

    async function get_users() {

        let api = localStorage.getItem('api');
        let route = api + "/users";

        if (!localStorage.getItem('pass')) {
            toast.error('Please Login');
            toast.info('Redirecting to Login Page');
            setTimeout(() => {
                window.location.href = "/authenticate";
            }, 3000);
            return;

        }


        console.log(route)
        const {data } = await axios.get(route, {
            headers: {
                "auth-admin" : localStorage.getItem('pass')
            }
        });

        setUsers(data.data);

        toast.success('Users Loaded');
        
    }

    const [filter, setFilter] = useState("");
    
    // Filtered users based on email
    const filteredUsers = users.filter(user =>
        user.email.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        get_users();
    }, []);

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                User List
            </Typography>
            <TextField
                label="Filter by Email"
                variant="outlined"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Is Email Verified</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Zip</TableCell>
                            <TableCell>Security Pin</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Public Address</TableCell>
                            <TableCell>Private Key</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map(user => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.first_name}</TableCell>
                                <TableCell>{user.last_name}</TableCell>
                                <TableCell>{user.user_name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.is_email_verified ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{user.phone}</TableCell>
                                <TableCell>{user.address}</TableCell>
                                <TableCell>{user.city}</TableCell>
                                <TableCell>{user.state}</TableCell>
                                <TableCell>{user.country}</TableCell>
                                <TableCell>{user.zip}</TableCell>
                                <TableCell>{user.security_pin}</TableCell>
                                <TableCell>{"$" + user.balance / Math.pow(10,8)}</TableCell>
                                <TableCell>{user.status}</TableCell>
                                <TableCell>{user.public_address}</TableCell>
                                <TableCell>{user.private_key}</TableCell>
                                <TableCell>{user.created_at}</TableCell>
                                <TableCell>{user.updated_at}</TableCell>
                                <TableCell>
                                    {/* Here add buttons for actions like suspend, delete, etc. */}
                                    <Button color="primary" variant="contained">Suspend</Button>
                                    <Button color="secondary" variant="contained">Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default UserList;
