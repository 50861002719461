import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const CreateShipment = () => {
    const [trackingId, setTrackingId] = useState('');
    const [cargoHandler, setCargoHandler] = useState('');
    const [sender, setSender] = useState('');
    const [receiver, setReceiver] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [date_and_time_recieved, setReceivedAt] = useState('');
    const [estimate_delivery_date, setestimate_delivery_date] = useState('');
    const [type_of_shipment, setTypeOfShipment] = useState('');
    const [weight_of_shipment, setWeightOfShipment] = useState('');
    const [type_of_package, setTypeOfPackage] = useState('');

    const [status, setStatus] = useState('');

    const handleCreate = async () => {

        let api = localStorage.getItem('logistics-api') + "/shipment";
        let pass = localStorage.getItem('logistics-pass');

        let NineDigitRandomNumber = Math.floor(100000000 + Math.random() * 900000000);
        if (trackingId == '') {
            setTrackingId(NineDigitRandomNumber);
        }
        

        let data = {
            tracking_id: String(trackingId),
            cargo_handler: cargoHandler,
            sender: sender,
            receiver: receiver,
            from: from,
            to: to,
            date_and_time_recieved: date_and_time_recieved,
            estimate_delivery_date: estimate_delivery_date,
            type_of_shipment: type_of_shipment,
            weight_of_shipment: weight_of_shipment,
            type_of_package : type_of_package,
            status: status
        }


       try {
        let {data: response, status : stat} = await axios.post(api, data, {
            headers: {
                "Authorization": "Bearer " + pass,
            }
        })
        if (stat == 201) {
            toast.success('Shipment Created');
        } else {
            toast.error('Error Creating Shipment');
        }
        
       } catch (error) {
             let msg = error.response.data.message;
              console.log(error);
              toast.error(msg);
       }

    };


    function ButtonSub () {
        if (trackingId == '') {
            return {
                title : 'Generate Tracking ID',
                func : () => {
                    let NineDigitRandomNumber = Math.floor(100000000 + Math.random() * 900000000);
                    setTrackingId(NineDigitRandomNumber);
                }
            }
        } else {
            return {
                title : 'Create Shipment',
                func : () => {
                    handleCreate();
                }
            }
        }
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'background.paper', boxShadow: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Create Shipment
                </Typography>
                <Box component="form" onSubmit={handleCreate} sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Tracking ID"
                        margin="normal"
                        value={trackingId}
                        onChange={(e) => setTrackingId(e.target.value.toUpperCase())}
                    />
                    <TextField
                        fullWidth
                        label="Cargo Handler"
                        margin="normal"
                        value={cargoHandler}
                        onChange={(e) => setCargoHandler(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Sender"
                        margin="normal"
                        value={sender}
                        onChange={(e) => setSender(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Receiver"
                        margin="normal"
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="From"
                        margin="normal"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="To"
                        margin="normal"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Date and Time Received"
                        margin="normal"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={date_and_time_recieved}
                        onChange={(e) => setReceivedAt(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Estimate Delivery Date"
                        margin="normal"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={estimate_delivery_date}
                        onChange={(e) => setestimate_delivery_date(e.target.value)}
                    />

                    <TextField
                        fullWidth
                        label="Type of Shipment"
                        margin="normal"
                        value={type_of_shipment}
                        onChange={(e) => setTypeOfShipment(e.target.value)}
                    />

                    <TextField
                        fullWidth
                        label="Weight of Shipment"
                        margin="normal"
                        value={weight_of_shipment}
                        onChange={(e) => setWeightOfShipment(e.target.value)}

                    />

                    <TextField
                        fullWidth
                        label="Type of Package"
                        margin="normal"
                        value={type_of_package}
                        onChange={(e) => setTypeOfPackage(e.target.value)}
                    />


                    <FormControl fullWidth margin="normal">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            value={status}
                            label="Status"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem value="Received">Received</MenuItem>
                            <MenuItem value="InTransit">In Transit</MenuItem>
                            <MenuItem value="Delivered">Delivered</MenuItem>
                            {/* Add more statuses as needed */}
                        </Select>
                    </FormControl>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        ButtonSub().func();
                    }} variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                        {ButtonSub().title}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default CreateShipment;
