import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import { toast } from 'react-toastify';
import axios from 'axios';

const PlansList = () => {
    // Example data - replace with real data
   const [plans, setPlans] = useState([]);

    async function get_plans() {

        let api = localStorage.getItem('api');
        let route = api + "/plans";

        if (!localStorage.getItem('pass')) {
            toast.error('Please Login');
            toast.info('Redirecting to Login Page');
            setTimeout(() => {
                window.location.href = "/authenticate";
            }, 3000);
            return;

        }


        console.log(route)
        const {data } = await axios.get(route, {
            headers: {
                "auth-admin" : localStorage.getItem('pass')
            }
        });


        setPlans(data);

        toast.success('Plans Loaded');
        
    }


    useEffect(() => {
        get_plans();
    }, []);

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Plans List
            </Typography>

            {/* Plans Table */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Min Amount</TableCell>
                            <TableCell>Max Amount</TableCell>
                            <TableCell>Interest Schedule</TableCell>
                            <TableCell>Possible Durations</TableCell>
                            <TableCell>Possible Interest Rates</TableCell>
                            <TableCell>Possible Interest Rates for Insurance</TableCell>
                            <TableCell>Weekdays Only</TableCell>
                            <TableCell>Weekends Only</TableCell>
                            <TableCell>Mondays Only</TableCell>
                            <TableCell>Refund Capital</TableCell>
                            <TableCell>Email Notification</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {plans.map((plan) => (
                            <TableRow key={plan.id}>
                                <TableCell>{plan.id}</TableCell>
                                <TableCell>{plan.name}</TableCell>
                                <TableCell>{plan.description}</TableCell>
                                <TableCell>{"$" + plan.min_amount / Math.pow(10, 8)}</TableCell>
                                <TableCell>{"$" + plan.max_amount/ Math.pow(10, 8)}</TableCell>
                                <TableCell>{plan.interest_schedule}</TableCell>
                                <TableCell>{plan.possible_durations.join(', ') + " Days"}</TableCell>
                                <TableCell>{plan.possible_interest_rates.join(', ') + "%"} </TableCell>
                                <TableCell>{plan.possible_interest_rates_for_insurance.join(', ') + "%"}</TableCell>
                                <TableCell>{plan.weekdays_only ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{plan.weekends_only ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{plan.mondays_only ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{plan.refund_capital ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{plan.email_notification ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{plan.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default PlansList;
