import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
const TrackingList = () => {
    // Example tracking data - replace with real data
    const [trackings, setTrackings] = useState([]);


    async function getTrackings() {
        let api = localStorage.getItem('logistics-api');
        let pass = localStorage.getItem('logistics-pass');

        let route = api + "/shipments";

        let {data} = await axios.get(route, {
            headers: {
                "Authorization": "Bearer " + pass,
            }
        })

        console.log(data);

        console.log(data?.shipments);

        setTrackings(data?.shipments);

        toast.success(data?.shipments?.length + ' shipments Loaded');


    }


    useEffect(() => {
        getTrackings();
    }, []);

    const [filter, setFilter] = useState("");

    const filteredTrackings = trackings?.filter(
        tracking => tracking.tracking_id.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom style={{ color: '#1976d2' }}>
                Tracking List
            </Typography>
            <TextField
                label="Filter by Tracking ID"
                variant="outlined"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: '20px' }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#1976d2' }}>
                        <TableRow>
                            <TableCell style={{ color: 'white' }}>Tracking ID</TableCell>
                            <TableCell style={{ color: 'white' }}>Cargo Handler</TableCell>
                            <TableCell style={{ color: 'white' }}>Sender</TableCell>
                            <TableCell style={{ color: 'white' }}>Receiver</TableCell>
                            <TableCell style={{ color: 'white' }}>From</TableCell>
                            <TableCell style={{ color: 'white' }}>To</TableCell>
                            <TableCell style={{ color: 'white' }}>Received At</TableCell>
                            <TableCell style={{ color: 'white' }}>Estimate Delivery</TableCell>
                            <TableCell style={{ color: 'white' }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTrackings.map(tracking => (
                            <TableRow key={tracking.tracking_id}>
                                <TableCell>{tracking.tracking_id}</TableCell>
                                <TableCell>{tracking.cargo_handler}</TableCell>
                                <TableCell>{tracking.sender}</TableCell>
                                <TableCell>{tracking.receiver}</TableCell>
                                <TableCell>{tracking.from}</TableCell>
                                <TableCell>{tracking.to}</TableCell>
                                <TableCell>{tracking.date_and_time_recieved}</TableCell>
                                <TableCell>{tracking.estimate_delivery_date || "N/A"}</TableCell>
                                <TableCell>{tracking.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TrackingList;
