import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

const AddNewPlan = () => {
    // State for new plan
    const [newPlan, setNewPlan] = useState({
        id: "",
        name: "",
        description: "a great description",
        min_amount: 10,
        max_amount: 100,
        interest_schedule: "Daily",
        possible_durations: [12],
        possible_interest_rates: [15],
        possible_interest_rates_for_insurance: [0],
        weekdays_only: false,
        weekends_only: false,
        mondays_only: false,
        refund_capital: false,
        email_notification: false,
        status: "Active"
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        // Handle the change differently if it's for an array or checkbox
        if (Array.isArray(newPlan[name])) {
            // Assuming a string of comma-separated numbers for array inputs
            setNewPlan(prev => ({
                ...prev,
                [name]: value.split(',').map(v => parseInt(v.trim(), 10)).filter(v => !isNaN(v))
            }));
        } else {
            setNewPlan(prev => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            }));


        }

        // set plan id to lowercase name and remove spaces and special characters
        if (name === "name") {
            setNewPlan(prev => ({
                ...prev,
                id: value.toLowerCase().replace(/[^a-z0-9]/g, '')
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Implement the logic to add the new plan.
        await create_plan();
        // Reset form or provide feedback
    };


    async function create_plan() {
        let api = localStorage.getItem('api');
        let route = api + "/plan/create";

        if (!localStorage.getItem('pass')) {
            toast.error('Please Login');
            toast.info('Redirecting to Login Page');
            setTimeout(() => {
                window.location.href = "/authenticate";
            }, 3000);
            return;

        }

        if (!newPlan.id || !newPlan.name || !newPlan.description || !newPlan.min_amount || !newPlan.max_amount || !newPlan.interest_schedule || !newPlan.possible_durations || !newPlan.possible_interest_rates || !newPlan.possible_interest_rates_for_insurance || !newPlan.status) {
            toast.error('Please Fill All Fields');
            return;
        }

        if (newPlan.possible_durations.length !== newPlan.possible_interest_rates.length || newPlan.possible_durations.length !== newPlan.possible_interest_rates_for_insurance.length) {
            toast.error('Please Ensure Possible Durations, Interest Rates and Insurance Rates are of the same length');
            return;
        }

        let body = {
            id: newPlan.id,
            name: newPlan.name,
            description: newPlan.description,
            min_amount: Number(newPlan.min_amount),
            max_amount: Number(newPlan.max_amount),
            interest_schedule: newPlan.interest_schedule,
            possible_durations: newPlan.possible_durations,
            possible_interest_rates: newPlan.possible_interest_rates,
            possible_interest_rates_for_insurance: newPlan.possible_interest_rates_for_insurance,
            weekdays_only: newPlan.weekdays_only,
            weekends_only: newPlan.weekends_only,
            mondays_only: newPlan.mondays_only,
            refund_capital: newPlan.refund_capital,
            email_notification: newPlan.email_notification,
            status: newPlan.status
        }


        console.log(route)
        const {data, status } = await axios.post(route, [body], {
            headers: {
                "auth-admin" : localStorage.getItem('pass')
            }
        });




        if (status == 200) {
            toast.success('Plan Created');
        } else {
            toast.error('Error Creating Plan');
        }
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                Add New Plan
            </Typography>
            <form onSubmit={handleSubmit}>
                {/* Text and Number Inputs */}
                <TextField label="ID" name="id"
                disabled={true}
                 value={newPlan.id} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Name" name="name" value={newPlan.name} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Description" name="description" value={newPlan.description} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Min Amount" name="min_amount" type="number" value={newPlan.min_amount} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Max Amount" name="max_amount" type="number" value={newPlan.max_amount} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Interest Schedule" name="interest_schedule" value={newPlan.interest_schedule} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Possible Durations (comma-separated)" name="possible_durations" value={newPlan.possible_durations.join(', ')} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Possible Interest Rates (comma-separated)" name="possible_interest_rates" value={newPlan.possible_interest_rates.join(', ')} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Possible Interest Rates for Insurance (comma-separated)" name="possible_interest_rates_for_insurance" value={newPlan.possible_interest_rates_for_insurance.join(', ')} onChange={handleChange} fullWidth margin="normal" />

                {/* Checkbox Inputs */}
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={newPlan.weekdays_only} onChange={handleChange} name="weekdays_only" />} label="Weekdays Only" />
                    <FormControlLabel control={<Checkbox checked={newPlan.weekends_only} onChange={handleChange} name="weekends_only" />} label="Weekends Only" />
                    <FormControlLabel control={<Checkbox checked={newPlan.mondays_only} onChange={handleChange} name="mondays_only" />} label="Mondays Only" />
                    <FormControlLabel control={<Checkbox checked={newPlan.refund_capital} onChange={handleChange} name="refund_capital" />} label="Refund Capital" />
                    <FormControlLabel control={<Checkbox checked={newPlan.email_notification} onChange={handleChange} name="email_notification" />} label="Email Notification" />
                </FormGroup>

                {/* Status Dropdown or Text Field */}
                <TextField label="Status" name="status" value={newPlan.status} onChange={handleChange} fullWidth margin="normal" />

                {/* Submit Button */}
                <Button type="submit" variant="contained" color="primary">
                    Add Plan
                </Button>
            </form>
        </Container>
    );
};

export default AddNewPlan;
