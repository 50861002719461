import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenu}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Jet Admin
                </Typography>
                {/* Menu structure */}
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                  {
                    localStorage.getItem('panel') == "jet" &&
                    <><MenuItem onClick={() => {
                        window.location.href = '/users';
                    }}>Users</MenuItem>
                    <MenuItem onClick={() => {
                        window.location.href = '/plans';
                    }}>Plans</MenuItem>
                    <MenuItem onClick={() => {
                        window.location.href = '/investments';
                    }}>Investments</MenuItem>
                    <MenuItem onClick={() => {
                        window.location.href = '/add-plan';
                    }}>Add Plan</MenuItem>
                    <MenuItem onClick={() => {
                        window.location.href = '/manipulate-balance';
                    }}>Edit Balance</MenuItem>

                    </>  }

                    {
                        localStorage.getItem('panel') == "logistics" &&
                       <><MenuItem onClick={() => {
                            window.location.href = '/';
                        }}>Shipments</MenuItem>

                        <MenuItem onClick={() => {
                            window.location.href = '/update-shipment';
                        }}>Update Shipment</MenuItem>

                        <MenuItem onClick={() => {
                            window.location.href = '/create-shipment';
                        }}>Create Shipment</MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = '/reciept';
                        }}>Generate Reciept</MenuItem>
                        <MenuItem onClick={() => {
                            window.location.href = '/update-shipment-details';
                        }}>Update Details</MenuItem>
                        </>
                    }

                    <MenuItem onClick={() => {
                            window.location.href = '/authenticate';
                        }}>Authenticate</MenuItem>
                   
                    <MenuItem onClick={() => {
                        localStorage.clear();
                        }}>Clear Auth Tokens</MenuItem>


                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
