import React, { useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';


const Dashboard = () => {
    const data = {
        totalUsers: 1000,  // Example data
        totalInvestments: 500000,
        totalWithdrawals: 300000,
        licenseExpiry: "2024-01-01"  // Example expiry date
    };
    const [adminData, setAdminData] = useState({});

    async function get_data() {
        let api = localStorage.getItem('api');
        let route = api + "/admin-data";

        if (!localStorage.getItem('pass')) {
            toast.error('Please Login');
            toast.info('Redirecting to Login Page');
            setTimeout(() => {
                window.location.href = "/authenticate";
            }, 3000);
            return;

        }

        console.log(route)
        const {data } = await axios.get(route, {
            headers: {
                "auth-admin" : localStorage.getItem('pass')
            }
        });


        setAdminData(data.data);

        toast.success('Stats Loaded');
        
    }


    useEffect(() => {
        get_data();
    }, []);

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Dashboard
            </Typography>

            {/* Small Boxes for Totals */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Total Users
                            </Typography>
                            <Typography variant="h5">
                                {adminData.total_users}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Total Investment Count
                            </Typography>
                            <Typography variant="h5">
                                {adminData.total_investments}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Total Investment Amount
                            </Typography>
                            <Typography variant="h5">
                                ${adminData.total_investments_amount / Math.pow(10, 8)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* License Renewal Section */}
            <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
                Hosting Card
            </Typography>
            <Card>
                <CardContent>
                    <Typography color="textSecondary">
                        Expiry Date: "null"
                    </Typography>
                    <Button variant="contained" color="primary" style={{ marginTop: '1rem' }}>
                        Renew
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Dashboard;
