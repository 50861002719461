import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

const Authentication = ({setPanel, panel}) => {

    async function verifyLogistics(api , pass) {    
           try {
            let route = api + "/shipments";
            let {data, status} = await axios.get(route, {
                headers: {
                    "Authorization": "Bearer " + pass,
                }
                
            })

            if (status == 200) {
                return true;
            } else {
                return false;
            }
           } catch (error) {
               console.log(error);
                return false;
           }    
    }

    async function verifyJet(api , pass) {    
        try {
         let route = api + "/admin-data";

         console.log(route)
         let {data, status} = await axios.get(route, {
             headers: {
                 "auth-admin":  pass,
             }
             
         })

         if (status == 200) {
             return true;
         } else {
             return false;
         }
        } catch (error) {
            console.log(error);
             return false;
        }    
 }
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (event) => {

        let apiURL;
        // if username has a trailing / remove it
        if (username[username.length - 1] == "/") {
            apiURL = username.slice(0, -1);
        } else {
            apiURL = username;
        }

        if (!apiURL) {
            return;
        }
        event.preventDefault();
        if (panel == "jet") {

            toast.info('Verifying Credentials for inv');
            let res = await verifyJet(apiURL + "/admin", password);

            if (!res) {
                toast.error('Invalid Credentials');
                return;
            }

            localStorage.setItem('api', apiURL + "/admin");
            localStorage.setItem('pass', password);


        }

        if (panel == "logistics") {

            toast.info('Verifying Credentials for logistics');
            let res = await verifyLogistics(apiURL, password);
            if (!res) {
                toast.error('Invalid Credentials');
                return;
            }
            localStorage.setItem('logistics-api', apiURL);
            localStorage.setItem('logistics-pass', password);
        }


        // 
        toast.success('Login Successful');
        toast.info('Redirecting to Dashboard');
       
        if (panel == "jet") {
          window.location.href = '/';
        }

        if (panel == "logistics") {
          window.location.href = '/';
        }
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    Sign in to App
                </Typography>
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    {/* Dropdown for App Selection */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="app-select-label">App</InputLabel>
                        <Select
                            labelId="app-select-label"
                            id="app"
                            value={panel}
                            label="App"
                            onChange={(e) => {
                                setPanel(e.target.value);
                                localStorage.setItem('panel', e.target.value);
                            }}
                        >
                            <MenuItem value="jet">inv</MenuItem>
                            <MenuItem value="logistics">logistics</MenuItem>
                            <MenuItem value="">Loan Management</MenuItem>
                            <MenuItem value="">Banking</MenuItem>
                            <MenuItem value="">Trading/ Crypto Exchange</MenuItem>
                            {/* Add more apps as needed */}
                        </Select>
                    </FormControl>
                    {/* Username Input */}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    {/* Password Input */}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* Sign In Button */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Authentication;
