import React, { useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useEffect } from 'react';

const InvestmentsList = () => {
    // Example data - replace with real data
    const [investments, setInvestments] = useState([]);

    async function get_investments() {

        let api = localStorage.getItem('api');
        let route = api + "/admin-investments";

        if (!localStorage.getItem('pass')) {
            toast.error('Please Login');
            toast.info('Redirecting to Login Page');
            setTimeout(() => {
                window.location.href = "/authenticate";
            }, 3000);
            return;

        }


        console.log(route)
        const {data } = await axios.get(route, {
            headers: {
                "auth-admin" : localStorage.getItem('pass')
            }
        });

        setInvestments(data.data);

        toast.success('Investments Loaded');
        
    }

    useEffect(() => {
        get_investments();
    }, []);



    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    // Filtered investments based on filter state
    const filteredInvestments = investments.filter((investment) => {
        // Implement more sophisticated filtering logic as needed
        return Object.values(investment).some(value =>
            String(value).toLowerCase().includes(filter)
        );
    });

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Investments List
            </Typography>

            {/* ... Filtering Options ... */}
            <TextField
                label="Filter Investments"
                variant="outlined"
                value={filter}
                onChange={handleFilterChange}
                style={{ marginBottom: '1rem' }}
            />


            {/* Investments Table */}
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Plan ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Payment Method</TableCell>
                            <TableCell>Returns Earned</TableCell>
                            <TableCell>Investment Balance</TableCell>
                            <TableCell>Returns Per Schedule</TableCell>
                            <TableCell>Max Pay Count</TableCell>
                            <TableCell>Pay Count</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>ROI</TableCell>
                            <TableCell>Is Admin Paused</TableCell>
                            <TableCell>Fee Percentage</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Capital Refund</TableCell>
                            <TableCell>Email Notification</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Last Payment Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {investments.map((investment) => (
                            <TableRow key={investment.id}>
                                <TableCell>{investment.id}</TableCell>
                                <TableCell>{investment.plan_id}</TableCell>
                                <TableCell>{investment.user_id}</TableCell>
                                <TableCell>{"$"+investment.amount / Math.pow(10,8) }</TableCell>
                                <TableCell>{investment.start_date}</TableCell>
                                <TableCell>{investment.end_date}</TableCell>
                                {/* ... other cells ... */}
                                <TableCell>{investment.payment_method}</TableCell>
                                <TableCell>{"$"+investment.returns_earned / Math.pow(10,8)}</TableCell>
                                <TableCell>{"$"+investment.investment_balance  / Math.pow(10,8) }</TableCell>
                                <TableCell>{"$"+investment.returns_per_schedule / Math.pow(10,8)}</TableCell>
                                <TableCell>{investment.max_pay_count}</TableCell>
                                <TableCell>{investment.pay_count}</TableCell>
                                <TableCell>{investment.status}</TableCell>
                                <TableCell>{investment.roi + "%"}</TableCell>
                                <TableCell>{investment.is_admin_paused ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{investment.fee_percentage}%</TableCell>
                                <TableCell>{"$" + investment.total_amount / Math.pow(10,8)}</TableCell>
                                <TableCell>{investment.capital_refund ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{investment.email_notification ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{investment.created_at}</TableCell>
                                <TableCell>{investment.updated_at}</TableCell>
                                <TableCell>{investment.last_payment_date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default InvestmentsList;
