import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper
} from '@mui/material';
import axios from 'axios';
    import { toast } from 'react-toastify';

const UpdateShipment = () => {
    const [tracking, setTracking] = useState([]);
    const [newLocation, setNewLocation] = useState('');

    const [cargoHandler, setCargoHandler] = useState('');
    const [customsRemarks, setCustomsRemarks] = useState('');
    const [status, setStatus] = useState('');
    const [time, setTime] = useState('');

    

    const handleUpdate = async (event) => {
        event.preventDefault();
        // Implement the logic to update the shipment here
        // console.log({
        //     newLocation,
        //     cargoHandler,
        //     customsRemarks,
        //     status,
        //     time
        // });

        let api = localStorage.getItem('logistics-api');
        let url = api + "/shipment/" + tracking;

        let body = {
            new_location : newLocation,
            cargo_handler: cargoHandler,
            customs_remarks : customsRemarks,
            status : status,
            time : time
        }

        const {data: response, status : stat} = await axios.post(url, body, {
            headers : {
                "Authorization" : "Bearer " + localStorage.getItem('logistics-pass'),
            }
        });

        if (stat == 200) {
            toast.success('Shipment Updated');
        } else {
            toast.error('Error Updating Shipment');
        }
        // Show success message or handle the update
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'background.paper', boxShadow: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Update Shipment
                </Typography>
                <Box component="form" onSubmit={handleUpdate} sx={{ mt: 2 }}>
                <TextField
                        fullWidth
                        label="Tracking ID"
                        margin="normal"
                        value={tracking}
                        onChange={(e) => setTracking(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="New Location"
                        margin="normal"
                        value={newLocation}
                        onChange={(e) => setNewLocation(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Cargo Handler"
                        margin="normal"
                        value={cargoHandler}
                        onChange={(e) => setCargoHandler(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Customs Remarks"
                        margin="normal"
                        multiline
                        rows={2}
                        value={customsRemarks}
                        onChange={(e) => setCustomsRemarks(e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            value={status}
                            label="Status"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem value="OnHold">OnHold</MenuItem>
                            <MenuItem value="Received">Received</MenuItem>
                            <MenuItem value="InTransit">In Transit</MenuItem>
                            <MenuItem value="Delivered">Delivered</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Time"
                        margin="normal"
                        type="datetime-local"
                        InputLabelProps={{ shrink: true }}
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                        Update
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default UpdateShipment;
