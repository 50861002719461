import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem 
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

const ManipulateBalance = () => {
    // State for form fields
    const [transactionType, setTransactionType] = useState('');
    const [userId, setUserId] = useState('');
    const [amount, setAmount] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Implement submit logic here

        credit_user();
    }

    async function credit_user() {
        let api = localStorage.getItem('api');
        let route;

        if (transactionType == "Credit") {
             route = api + "/credit-balance";
        } else if (transactionType == "Debit") {
             route = api + "/debit-balance";
        }

        if (!localStorage.getItem('pass')) {
            toast.error('Please Login');
            toast.info('Redirecting to Login Page');
            setTimeout(() => {
                window.location.href = "/authenticate";
            }, 3000);
            return;

        }


        let body = {
            "user_id": userId.trim(),
            "amount": Number(amount)
        }


        console.log(route)
        const {data, status } = await axios.post(route, body, {
            headers: {
                "auth-admin" : localStorage.getItem('pass')
            }
        });


        if (status == 200) {
            toast.success('User Credited');
        } else {
            toast.error('Error Crediting');
        }
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                Edit User Balance
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="transaction-type-label">Transaction Type</InputLabel>
                    <Select
                        labelId="transaction-type-label"
                        id="transactionType"
                        value={transactionType}
                        label="Transaction Type"
                        onChange={(e) => setTransactionType(e.target.value)}
                    >
                        <MenuItem value="Credit">Credit</MenuItem>
                        <MenuItem value="Debit">Debit</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="User ID"
                    variant="outlined"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Amount"
                    variant="outlined"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Submit
                </Button>
            </form>
        </Container>
    );
};

export default ManipulateBalance;
